import React, { useEffect } from 'react'
import { hasPermission } from '../../utils/Permissions';

export default function LayoutAdminHeader({auth}) {

    function logout(e){
        e.preventDefault();
        document.getElementById('logout-form').submit();
    }

    return (
        <>
            {/* <!--begin::Header--> */}
            <div id="kt_app_header" className="app-header">
                <div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
                    {/* <!--begin::sidebar mobile toggle--> */}
                    <div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
                        <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                            {/* <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg--> */ }
                            <span className="svg-icon svg-icon-1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                                </svg>
                            </span>
                            {/* <!--end::Svg Icon--> */}
                        </div>
                    </div>
                    {/* <!--end::sidebar mobile toggle--> */}
                    {/* <!--begin::Mobile logo--> */}
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <a href="/metronic8/demo1/../demo1/index.html" className="d-lg-none">
                            <img alt="Logo" src="/images/logo.png" className="h-30px" />
                        </a>
                    </div>
                    {/* <!--end::Mobile logo--> */}
                    {/* <!--begin::Header wrapper--> */}
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                        {/* <!--begin::Menu wrapper--> */}
                        <div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">
                            {/* <!--begin::Menu--> */}
                            <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" id="kt_app_header_menu" data-kt-menu="true">
                                {/* <!--begin:Menu item--> */}
                                <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                </div>
                                {/* <!--end:Menu item--> */}
                            </div>
                            {/* <!--end::Menu--> */}
                        </div>
                        {/* <!--end::Menu wrapper--> */}
                        {/* <!--begin::Navbar--> */}
                        <div className="app-navbar flex-shrink-0">
                            {/* <!--begin::User menu--> */}
                            <div className="app-navbar-item ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                                {/* <!--begin::Menu wrapper--> */}
                                <div className="cursor-pointer symbol symbol-35px symbol-md-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                    <img src="/metronic/assets/media/avatars/blank.png" alt="user" />
                                </div>
                                {/* <!--begin::User account menu--> */}
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
                                    {/* <!--begin::Menu item--> */}
                                    <div className="menu-item px-3">
                                        <div className="menu-content d-flex align-items-center px-3">
                                            {/* <!--begin::Avatar--> */}
                                            <div className="symbol symbol-50px me-5">
                                                <img alt="Logo" src="/metronic/assets/media/avatars/blank.png" />
                                            </div>
                                            {/* <!--end::Avatar--> */}
                                            {/* <!--begin::Username--> */}
                                            <div className="d-flex flex-column">
                                                <div className="fw-bold d-flex align-items-center fs-5">{auth.user.name}</div>
                                                <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">{auth.user.email}</a>
                                            </div>
                                            {/* <!--end::Username--> */}
                                        </div>
                                    </div>
                                    {/* <!--end::Menu item--> */}
                                    {/* <!--begin::Menu item--> */}
                                    {hasPermission('quotes.appointments') && (
                                        <div className="menu-item px-5">
                                            <a href={route("appointments.list")} className="menu-link px-5">
                                                <span className="menu-text">Ver citas programadas</span>
                                                <span className="menu-badge">
                                                    <span className="badge badge-light-danger badge-circle fw-bold fs-7">{ auth.user.notifications }</span>
                                                </span>
                                            </a>
                                        </div>
                                    )}
                                    {/* <!--end::Menu item--> */}
                                    {/* <!--begin::Menu separator--> */}
                                    <div className="separator my-2"></div>
                                    {/* <!--end::Menu separator--> */}
                                    {/* <!--begin::Menu item--> */}
                                    <div className="menu-item px-5">
                                        <a href="#" className="menu-link px-5" onClick={logout}>
                                            Sign Out
                                        </a>
                                    </div>
                                    {/* <!--end::Menu item--> */}
                                </div>
                                {/* <!--end::User account menu--> */}
                                {/* <!--end::Menu wrapper--> */}
                            </div>
                            {/* <!--end::User menu--> */}
                        </div>
                        {/* <!--end::Navbar--> */}
                    </div>
                    {/* <!--end::Header wrapper--> */}
                </div>
            </div>
            {/* <!--end::Header--> */}
        </>
    );
}
